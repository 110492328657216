import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Header from './../includes/Header'
import LeftPanel from './../includes/LeftPanel'
import Footer from './../includes/Footer'

class MessageMainCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {

        return (
            <React.Fragment>
                <Header />
                <LeftPanel />
                <div id="main-container">

                    <div className="main-header clearfix">
                        <div className="page-title" style={{width: '100%'}}>
                            <h3 className="no-margin">Manage Content</h3>
                        </div>{/* /page-title */}
                    </div>{/* /main-header */}

                    <div className="padding-md">
                        <div className="panel panel-default table-responsive">


                            <table className="table table-striped" id="responsiveTable">
                                <thead>
                                    <tr>
                                        <th style={{ width: "93%" }}>Message Category</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Create Post</td>
                                        <td>
                                            <Link to="message-category/createPost">
                                                <button type="button" className="btn btn-primary btn-sm"><i className="fa fa-eye" aria-hidden="true"></i> Subcategory</button>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Delete Post </td>
                                        <td>
                                            <Link to="message-category/deletePost">
                                                <button type="button" className="btn btn-primary btn-sm"><i className="fa fa-eye" aria-hidden="true"></i> Subcategory</button>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Edit Post</td>
                                        <td>
                                            <Link to="message-category/editPost">
                                                <button type="button" className="btn btn-primary btn-sm"><i className="fa fa-eye" aria-hidden="true"></i> Subcategory</button>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Edit Post Content</td>
                                        <td>
                                            <Link to="message-category/editPostContent">
                                                <button type="button" className="btn btn-primary btn-sm"><i className="fa fa-eye" aria-hidden="true"></i> Subcategory</button>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Edit Post Location Date Time</td>
                                        <td>
                                            <Link to="message-category/editPostLocationDateTime">
                                                <button type="button" className="btn btn-primary btn-sm"><i className="fa fa-eye" aria-hidden="true"></i> Subcategory</button>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Post Like Comment Reply</td>
                                        <td>
                                            <Link to="message-category/postLikeCommentReply">
                                                <button type="button" className="btn btn-primary btn-sm"><i className="fa fa-eye" aria-hidden="true"></i> Subcategory</button>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Reminder</td>
                                        <td>
                                            <Link to="message-category/reminder">
                                                <button type="button" className="btn btn-primary btn-sm"><i className="fa fa-eye" aria-hidden="true"></i> Subcategory</button>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>RSVP Response</td>
                                        <td>
                                            <Link to="message-category/RSVPResponse">
                                                <button type="button" className="btn btn-primary btn-sm"><i className="fa fa-eye" aria-hidden="true"></i> Subcategory</button>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Send OTP</td>
                                        <td>
                                            <Link to="message-category/sendOtp">
                                                <button type="button" className="btn btn-primary btn-sm"><i className="fa fa-eye" aria-hidden="true"></i> Subcategory</button>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Tagged User</td>
                                        <td>
                                            <Link to="message-category/mentionedUser">
                                                <button type="button" className="btn btn-primary btn-sm"><i className="fa fa-eye" aria-hidden="true"></i> Subcategory</button>
                                            </Link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}


export default MessageMainCategory