import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import firebase from 'firebase/app'
import { signIn } from '../../store/actions/authActions'


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailError: null,
            passwordError: null,
            forgotPasswordModalOpen: false,
            forgotPasswordErrorMessage: '',
            forgotPasswordSuccessMessage: ''
        }
    }

    onLoginSubmit = (e) => {
        e.preventDefault();

        this.setState({
            emailError: null,
            passwordError: null
        })

        let errorCount = 0;
        const formEmail = e.target.formControlsUsername.value.trim();
        const formPassword = e.target.formControlsPassword.value.trim();

        let emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailRegex.test(formEmail)) {
            errorCount++;
            this.setState({
                emailError: 'Please enter valid email'
            })
        }

        if (formPassword == '') {
            errorCount++;
            this.setState({
                passwordError: 'Please enter password'
            })
        }

        if (errorCount == 0) {
            const loginData = {
                email: formEmail,
                password: formPassword
            }

            this.props.signIn(loginData);
        }
    }

    onForgotPasswordSubmit = (e) => {
        e.preventDefault();
        let errorCount = 0;
        const forgotEmail = e.target.forgotPasswordEmail.value.trim();

        this.setState({
            forgotPasswordErrorMessage: '',
            forgotPasswordSuccessMessage: ''
        })

        let emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailRegex.test(forgotEmail)) {
            errorCount++;
            this.setState({
                forgotPasswordErrorMessage: 'Please enter valid email'
            })
        }
        //alert(forgotEmail)
        if (errorCount == 0) {
            var actionCodeSettings = {
                // After password reset, the user will be give the ability to go back
                // to this page.
                url: window.location.protocol + '//' + window.location.host + '/login',
                handleCodeInApp: false
            };

            firebase.auth().sendPasswordResetEmail(forgotEmail, actionCodeSettings)
                .then(() => {
                    this.setState({
                        forgotPasswordSuccessMessage: 'Password reset email sent, check your inbox.'
                    })
                }).catch((error) => {
                    this.setState({
                        forgotPasswordErrorMessage: error.message
                    })
                })
        }
    }

    render() {

        if (this.props.fireBaseAuth.uid) {
            console.log(this.props.fireBaseAuth)
            return <Redirect to='/' />
        }

        let forgotPasswordModalCss = 'modal fade ';
        if (this.state.forgotPasswordModalOpen) {
            forgotPasswordModalCss = forgotPasswordModalCss + ' in';
        }

        return (

            <React.Fragment>
                <div class={forgotPasswordModalCss} role="dialog" >
                    <div class="modal-dialog" style={{ margin: '10% auto' }}>

                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" onClick={() => this.setState({ forgotPasswordModalOpen: !this.state.forgotPasswordModalOpen })}>&times;</button>
                                <h4 class="modal-title">Forgot Password</h4>
                            </div>
                            <div class="modal-body">
                                <form className="form-login" onSubmit={this.onForgotPasswordSubmit}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="input-group">
                                                <input type="text" placeholder="Email" id="forgotPasswordEmail" className="form-control input-sm" />
                                                <div className="input-group-btn">
                                                    <button type="submit" className="btn btn-sm btn-success">Submit</button>
                                                </div>

                                            </div>
                                            <div className="required">{this.state.forgotPasswordErrorMessage}</div>
                                        </div>{/* /.col */}
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <div style={{ textAlign: 'center', position: 'absolute', color: 'green', fontSize: 15 }}> {this.state.forgotPasswordSuccessMessage} </div>
                                <button type="button" class="btn btn-default" onClick={() => this.setState({ forgotPasswordModalOpen: !this.state.forgotPasswordModalOpen })}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="login-wrapper">
                    <div className="text-center">
                        <h2 className="fadeInUp animation-delay8" style={{ fontWeight: 'bold' }}>
                            <span className="text-success">InsideSeat</span> <span style={{ color: '#ccc', textShadow: '0 1px #fff' }}>Admin</span>
                        </h2>
                    </div>
                    <div className="login-widget animation-delay1">
                        <div className="panel panel-default">
                            <div className="panel-heading clearfix">
                                <div className="pull-left">
                                    <i className="fa fa-lock fa-lg" /> Login
                            </div>
                            </div>
                            <div className="panel-body">
                                <form className="form-login" onSubmit={this.onLoginSubmit}>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input type="text" placeholder="Email" id="formControlsUsername" className="form-control input-sm bounceIn animation-delay2" />
                                        <div className="required">{this.state.emailError}</div>
                                    </div>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input type="password" placeholder="Password" id="formControlsPassword" className="form-control input-sm bounceIn animation-delay4" />
                                        <div className="required">{this.state.passwordError}</div>
                                    </div>
                                    <div style={{ textAlign: 'center', color: 'red' }}>{this.props.authError}</div>
                                    {/* <div className="form-group">
                                    <label className="label-checkbox inline">
                                        <input type="checkbox" className="regular-checkbox chk-delete" />
                                        <span className="custom-checkbox info bounceIn animation-delay4" />
                                    </label>
                                    Remember me
                                </div> */}
                                    <div className="seperator" />
                                    <hr />
                                    <a style={{ color: '#428bca' }} onClick={() => this.setState({ forgotPasswordModalOpen: !this.state.forgotPasswordModalOpen })}>Forgot Password ?</a>
                                    <button className="btn btn-success btn-sm bounceIn animation-delay5 login-link pull-right"><i className="fa fa-sign-in" /> Sign in</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        fireBaseAuth: state.firebase.auth,
        authError: state.auth.authError
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (creds) => dispatch(signIn(creds))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)