import React, { Component } from 'react'
import * as Datetime from 'react-datetime'
import { Redirect } from 'react-router-dom'
import Geosuggest from 'react-geosuggest'
import axios from 'axios'
import qs from 'qs'
import * as mom from 'moment'
import $ from 'jquery'
import _ from 'underscore'
import { BitlyClient } from 'bitly'
import VideoThumbnail from 'react-video-thumbnail';
import firebase from 'firebase/app'
import { adminUserPhoneNo, bitlyAccessToken, fireBaseCloudFunctionBaseUrl } from '../../config/Config'
import Header from './../includes/Header'
import LeftPanel from './../includes/LeftPanel'
import Footer from './../includes/Footer'

import '../../assets/css/react-datetime.css'
import '../../assets/css/geosuggest.css'
import '../../assets/css/card.css'

const bitly = new BitlyClient(bitlyAccessToken, {});

class CreatePost extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: 'step1', // step1 or step2
            urlMetaData: {
                url: '',
                bitlyUrl: '',
                title: '',
                description: '',
                image: ''
            },
            formData: {
                title: '',
                description: '',
                isDescriptionError: false,
                imageOrVideo: '',
                imageOrVideoDownloadURL: '',
                imageOrVideoFileSize: '',
                videoThumbnailUrl: '',
                imageOrVideoFileType: 0, // 1=Image, 2=Video

                location: '',
                locationData: {},
                eventDateAndTime: '',
                eventDateAndTimeError: false,
                eventDateAndTimeErrorTxt: '',
                link: '',
                isLinkError: false,
                isLinkGettingMetaData: false,
                postTypeArr: []
            },
            videoFile: '',
            videoRavValue: '',

            adminUserData: {},
            renderLinkPreview: false,
            renderImageOrVideoPreview: null,

            isFetchingUsers: true,
            allUsers: [],
            fileteredUsers: [],

            filterCountry: [],
            filterLocality: [],
            filterCity: [],
            filterTimeZone: [],
            filterUserType: 'all', // all RO filtered
            redirect: false,

            isFileUploading: false,
            isFileUploadingPercentage: 0,
            isUserSubmitting: false,
            totalInvitedUsersCount: 1,
            postSubmitedSuccessfuly: false
        }
    }

    componentDidMount = () => {
        this.getUsers()

        // Get admin user data//
        firebase.ref(`/users`)
            .child(adminUserPhoneNo)
            .once('value', snap => {
                const adminUserData = snap.val();
                if (adminUserData != null) {
                    if (!_.has(adminUserData, "profileImage")) {
                        adminUserData.profileImage = ''
                    }

                    //adminUserData.isChecked == true;
                    adminUserData.phone_number = adminUserPhoneNo;

                    this.setState({
                        adminUserData: adminUserData
                    })
                }

            })
            .catch((error) => {
                alert('Error in getting chat data')
                console.log(error);
            });
    }

    text_truncate = (str, length, ending) => {
        if (length == null) {
            length = 100;
        }
        if (ending == null) {
            ending = '...';
        }
        if (str.length > length) {
            return str.substring(0, length - ending.length) + ending;
        } else {
            return str;
        }
    };

    getUsers = () => {
        firebase.ref(`/users`)
            .orderByChild("createdOn")
            .once('value', snap => {
                const userData = snap.val();
                if (userData != null) {
                    let mainData = [];
                    _.object(_.map(userData, function (value, key) {
                        value.phone_number = key;
                        value.isChecked = true;
                        if (!_.has(value, "Location")) {
                            value.Location = {
                                country: '',
                                locality: '',
                                city: ''
                            }
                        }
                        else {
                            if (!_.has(value.Location, "country")) {
                                value.Location.country = ''
                            }
                            if (!_.has(value.Location, "locality")) {
                                value.Location.locality = ''
                            }
                            if (!_.has(value.Location, "city")) {
                                value.Location.city = ''
                            }
                        }

                        if (!_.has(value, "TimeZone")) {
                            value.TimeZone = ''
                        }

                        if (!_.has(value, "user_name")) {
                            value.user_name = ''
                        }

                        if (!_.has(value, "profileImage")) {
                            value.profileImage = ''
                        }

                        if (!_.has(value, "notification_key_name")) {
                            value.notification_key_name = ''
                        }

                        if (!_.has(value, "createdOn")) {
                            value.createdOn = ''
                        }

                        if (key != adminUserPhoneNo && key.length > 5) {
                            mainData.push(value);
                        }

                        return value;
                    }));

                    //console.log(mainData);

                    this.setState({
                        allUsers: mainData,
                        fileteredUsers: mainData
                    })

                    var allHasLocation = mainData.filter(function (user) {
                        return user.Location.country != "";
                    });


                    let filterCountry = [];
                    let filterLocality = [];
                    let filterCity = [];
                    let filterTimeZone = [];

                    _.map(allHasLocation, (value) => {
                        let countryVal = value.Location.country;
                        let localityVal = value.Location.locality;
                        let cityVal = value.Location.city;
                        let timeZoneVal = value.TimeZone;

                        if (countryVal != '' && !filterCountry.includes(countryVal)) {
                            filterCountry.push(countryVal);
                        }
                        if (localityVal != '' && !filterLocality.includes(localityVal)) {
                            filterLocality.push(localityVal);
                        }
                        if (cityVal != '' && !filterCity.includes(cityVal)) {
                            filterCity.push(cityVal);
                        }
                        if (timeZoneVal != '' && !filterTimeZone.includes(timeZoneVal)) {
                            filterTimeZone.push(timeZoneVal);
                        }
                    });

                    this.setState({
                        filterCountry,
                        filterLocality,
                        filterCity,
                        filterTimeZone
                    })
                    console.log(filterCountry);
                    console.log(filterLocality);
                    console.log(filterCity);

                    console.log(allHasLocation);
                }

                this.setState({
                    isFetchingUsers: false
                })
            })
            .catch((error) => {
                alert('Error in getting chat data')
                console.log(error);
            });
    }

    imageOrVideoChange = () => {

        var filename = $("#file_multi_videoor_image").val().replace(/C:\\fakepath\\/i, '');
        // For image
        var imageFileExtension = ['jpeg', 'jpg', 'png'];
        if ($.inArray(filename.split('.').pop().toLowerCase(), imageFileExtension) >= 0) {

            this.setState({
                renderImageOrVideoPreview: 'image'
            }, () => {
                var reader = new FileReader();
                reader.onload = function (e) {
                    document.getElementById("image-preview").src = e.target.result;
                };
                reader.readAsDataURL($("#file_multi_videoor_image")[0].files[0]);
            })
        }

        // For video
        var videoFileExtension = ['mp4', 'webm'];
        if ($.inArray(filename.split('.').pop().toLowerCase(), videoFileExtension) >= 0) {
            this.setState({
                renderImageOrVideoPreview: 'video'
            }, () => {
                var $source = $('#video_here');
                $source[0].src = URL.createObjectURL($("#file_multi_videoor_image")[0].files[0]);
                $source.parent()[0].load();


                this.setState({
                    videoFile: ''
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            videoFile: URL.createObjectURL($("#file_multi_videoor_image")[0].files[0])
                        })
                    }, 500)

                })
            })
        }

        if (filename == '') {
            this.setState({
                renderImageOrVideoPreview: null
            })
        }
    }

    createThumbnalVideo = (varue) => {
        //console.log(varue)
        this.setState({
            videoRavValue: varue
        })

    }

    removeImageOrVideo = () => {
        this.setState({
            renderImageOrVideoPreview: null
        })
        $('#file_multi_videoor_image').val('');
    }

    getMetaContent = (html, url) => {
        html = $.parseHTML(html);

        let bitlyLomgUrl = url;
        if (!url.match('^http')) {
            bitlyLomgUrl = 'https://' + bitlyLomgUrl
        }

        bitly
            .shorten(bitlyLomgUrl)
            .then((result) => {
                const metaData = {
                    url: url,
                    bitlyUrl: result.url,
                    title: '',
                    description: '',
                    image: ''
                }
                $.each(html, function (i, el) {

                    if (el.nodeName == 'META') {
                        if (el.attributes.hasOwnProperty('property') && el.attributes.hasOwnProperty('content') && el.attributes.property.nodeValue == 'og:image') {
                            metaData.image = el.attributes.content.nodeValue;
                        }
                        else if (el.attributes.hasOwnProperty('itemprop') && el.attributes.hasOwnProperty('content') && el.attributes.itemprop.nodeValue == 'image') {
                            if(url == 'google.com' || url == 'www.google.com' || url == 'https://google.com' || url == 'http://google.com' || url == 'http://www.google.com' || url == 'http://www.google.com'){
                                metaData.image = 'https://www.google.com/' + el.attributes.content.nodeValue;
                            }
                            else{
                                metaData.image = url + el.attributes.content.nodeValue;
                            }
                        }

                        if (el.name == 'description') {
                            metaData.description = el.content;
                        }
                    }
                    else if (el.nodeName == 'TITLE') {
                        metaData.title = el.innerText;
                    }
                });

                this.setState({
                    urlMetaData: metaData
                });
            })
            .catch(function (error) {
                console.error(error);
            });


    }

    getMetaDataFromUrl = () => {
        let urlVal = this.state.formData.link;

        if (urlVal != '') {
            const { formData } = this.state;
            var res = urlVal.trim().match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            if (res == null) {
                const newFormData = {
                    ...formData,
                    isLinkError: true
                };
                this.setState({ formData: newFormData });
            }
            else {

                const newFormData = {
                    ...formData,
                    isLinkError: false,
                    isLinkGettingMetaData: true
                };
                this.setState({ formData: newFormData });

                const metaData = {
                    url: urlVal,
                    title: '',
                    description: '',
                    image: ''
                }

                let renderLinkPreview = false;
                if (urlVal != '') {
                    renderLinkPreview = true;
                }
                this.setState({
                    renderLinkPreview: renderLinkPreview,
                    urlMetaData: metaData
                })

                axios.get('https://cors-anywhere.herokuapp.com/' + urlVal)
                    .then((response) => {
                        const { formData } = this.state;
                        const newFormData = {
                            ...formData,
                            isLinkGettingMetaData: false
                        };
                        this.setState({ formData: newFormData });
                        this.getMetaContent(response.data.toString(), urlVal);
                    })
                    .catch((error) => {
                        const { formData } = this.state;
                        const newFormData = {
                            ...formData,
                            isLinkGettingMetaData: false
                        };
                        this.setState({ formData: newFormData });

                        this.getMetaContent('', urlVal);

                    })
                    .finally(function () {
                        // always executed
                    });
            }
        }

    }

    onDateChange = (value) => {
        const { formData } = this.state;
        const newData = {
            ...formData,
            eventDateAndTime: value
        };

        this.setState({ formData: newData }, () => {
            this.updateEventTitleDescription();
        });
    }


    updateEventTitleDescription = () => {
        let eventDescription = this.state.formData.title;

        if (this.state.formData.location != '') {
            eventDescription = eventDescription + ' at ' + this.state.formData.location
        }
        if (this.state.formData.eventDateAndTime != '') {
            eventDescription = eventDescription + ' on ' + mom(this.state.formData.eventDateAndTime).format('MMMM Do YYYY, h:mm A')
        }

        const { formData } = this.state;
        const newFormData = {
            ...formData,
            description: eventDescription
        };

        this.setState({ formData: newFormData });
    }

    handleChangeFor = (propertyName) => (event) => {
        const { formData } = this.state;

        if (propertyName == 'postType') {

            let postTypeArr = this.state.formData.postTypeArr;
            if (event.target.checked) {
                let hasValueIndex = $.inArray(event.target.value, postTypeArr);
                if (hasValueIndex < 0) {
                    postTypeArr.push(event.target.value);
                }
            }
            else {
                let hasValueIndex = $.inArray(event.target.value, postTypeArr);
                postTypeArr.splice(hasValueIndex, 1);
            }
            const newContact = {
                ...formData,
                postTypeArr: postTypeArr
            };

            this.setState({ formData: newContact }, () => {
                this.updateEventTitleDescription();
            });

        }
        else {
            const newContact = {
                ...formData,
                [propertyName]: event.target.value
            };

            this.setState({ formData: newContact }, () => {
                this.updateEventTitleDescription();
            });
        }
    }

    onLocationSuggestSelect = (suggest) => {
        let label = '';
        if (suggest != undefined) {
            label = suggest.label
        }

        console.log(suggest)

        const { formData } = this.state;
        const newContact = {
            ...formData,
            location: label,
            locationData: suggest
        };

        this.setState({ formData: newContact }, () => {
            this.updateEventTitleDescription();
        });

    }

    submitFirstStep = () => {
        let errorCount = 0;

        let isDescriptionError = false;
        if (this.state.formData.title.trim() == '') {
            errorCount++;
            isDescriptionError = true;

        }

        let isLinkError = false;
        if (this.state.formData.link.trim() != '') {
            var res = this.state.formData.link.trim().match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            if (res == null) {
                errorCount++;
                isLinkError = true;
            }
        }

        let eventDateAndTimeError = false;
        let eventDateAndTimeErrorTxt = '';
        if (this.state.formData.eventDateAndTime != '') {
            var myDate = new Date(this.state.formData.eventDateAndTime);
            var today = new Date();
            if (!mom(this.state.formData.eventDateAndTime, 'YYYY-MM-DD', true)._isValid) {
                //Dibya
                errorCount++;
                eventDateAndTimeError = true;
                eventDateAndTimeErrorTxt = 'Please enter valid date.';
            }
            else if (myDate < today) {
                errorCount++;
                eventDateAndTimeError = true;
                eventDateAndTimeErrorTxt = 'You cannot enter past date.';
            }
        }

        const { formData } = this.state;
        const newFormData = {
            ...formData,
            isDescriptionError: isDescriptionError,
            eventDateAndTimeError: eventDateAndTimeError,
            eventDateAndTimeErrorTxt: eventDateAndTimeErrorTxt,
            isLinkError: isLinkError
        };

        this.setState({ formData: newFormData }, () => {
            if (errorCount == 0) {
                this.submitStep2();
            }
        });
    }

    goPrev = () => {
        this.setState({
            currentStep: 'step1'
        })
    }

    handleSearch = (field, type) => {

        if (type != undefined) {
            this.setState({
                filterUserType: type
            })
        }
        let filteredUsers = this.state.allUsers;

        // console.log(filteredUsers);
        // filteredUsers = filteredUsers.filter((user) => {
        //     return user.phone_number == '8850082898';
        // });


        if ((field == 'filterUserType' && type == 'filtered') || (field == 'doFilter')) {

            setTimeout(() => {
                let countrySearchVal = $('#countrySearchVal').val();
                let localitySearchVal = $('#localitySearchVal').val();
                let citySearchVal = $('#citySearchVal').val();
                let timiZoneSearchVal = $('#timiZoneSearchVal').val();

                if (countrySearchVal != '') {
                    filteredUsers = filteredUsers.filter((user) => {
                        return user.Location.country == countrySearchVal;
                    });
                }

                if (localitySearchVal != '') {
                    filteredUsers = filteredUsers.filter((user) => {
                        return user.Location.locality == localitySearchVal;
                    });
                }

                if (citySearchVal != '') {
                    filteredUsers = filteredUsers.filter((user) => {
                        return user.Location.city == citySearchVal;
                    });
                }

                if (timiZoneSearchVal != '') {
                    filteredUsers = filteredUsers.filter((user) => {
                        return user.TimeZone == timiZoneSearchVal;
                    });
                }

                this.setState({
                    fileteredUsers: filteredUsers
                })
            }, 200)
        }
        else {
            this.setState({
                fileteredUsers: filteredUsers
            })
        }
    }

    handleSelectAllUsers = (event) => {
        let fileteredUsers = this.state.fileteredUsers
        fileteredUsers.forEach(eachUser => eachUser.isChecked = event.target.checked)
        this.setState({ fileteredUsers: fileteredUsers })
    }

    handleSelectUnselectUser = (event) => {
        let fileteredUsers = this.state.fileteredUsers
        fileteredUsers.forEach(user => {
            if (user.phone_number === event.target.value)
                user.isChecked = event.target.checked
        })
        this.setState({ fileteredUsers: fileteredUsers })
    }

    submitStep2 = () => {
        let selectedUsers = [];

        let withOutAdminUsers = this.state.fileteredUsers.filter((user) => {
            return user.isChecked == true;
        });;

        if (withOutAdminUsers.length > 0) {
            /*** STEP 1 ***/
            var eventRefId = firebase.ref().child('events').push().key;
            firebase.ref().child('users').child(adminUserPhoneNo).child('created_events').child(eventRefId).set(true);
            firebase.ref().child('users').child(adminUserPhoneNo).child('last_created_event').set(eventRefId);
            selectedUsers.unshift(this.state.adminUserData);


            this.setState({
                isUserSubmitting: true
            })

            if ($('#file_multi_videoor_image').val() != '') {

                this.setState({
                    isFileUploading: true,
                    isFileUploadingPercentage: 0
                })

                var storageRef = firebase.storage().ref();
                const file = document.querySelector('#file_multi_videoor_image').files[0];
                const fileSize = file.size;
                const fileType = file.type;

                const fileTypeArray = fileType.split('/');

                let imageOrVideoFileType = 0;
                if (fileTypeArray[0] == 'image') {
                    //Image
                    imageOrVideoFileType = 1;
                }
                else {
                    //Video
                    imageOrVideoFileType = 2;
                }



                if (imageOrVideoFileType == 2) { // Video
                    // Video thumbnel start
                    let videoThumbUrl = ''
                    const vidThumbMetadata = { contentType: 'image/png' };
                    var strImage = this.state.videoRavValue.replace(/^data:image\/[a-z]+;base64,/, "");
                    var uploadTaskVideoThumb = storageRef.child(`${adminUserPhoneNo}/users/events/eventImage_${eventRefId}` + 'videoThumb.png').putString(strImage, 'base64', vidThumbMetadata);

                    uploadTaskVideoThumb.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                        (snapshot) => {
                            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                            // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            // console.log('Upload is ' + progress + '% done');

                        }, (error) => {
                            console.log(error);
                        }, () => {
                            // Upload completed successfully, now we can get the download URL
                            uploadTaskVideoThumb.snapshot.ref.getDownloadURL().then((videoThumbDownloadURL) => {
                                console.log(videoThumbDownloadURL);
                                videoThumbUrl = videoThumbDownloadURL;

                                const metadata = { contentType: fileType };
                                var uploadTask = storageRef.child(`${adminUserPhoneNo}/users/events/eventImage_${eventRefId}` + file.name).put(file, metadata);

                                uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                                    (snapshot) => {
                                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                                        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                        console.log('Upload is ' + progress + '% done');
                                        this.setState({
                                            isFileUploadingPercentage: parseInt(progress)
                                        })
                                        switch (snapshot.state) {
                                            case firebase.storage.TaskState.PAUSED: // or 'paused'
                                                console.log('Upload is paused');
                                                break;
                                            case firebase.storage.TaskState.RUNNING: // or 'running'
                                                console.log('Upload is running');
                                                break;
                                        }
                                    }, (error) => {

                                        // A full list of error codes is available at
                                        // https://firebase.google.com/docs/storage/web/handle-errors
                                        switch (error.code) {

                                        }
                                    }, () => {
                                        // Upload completed successfully, now we can get the download URL
                                        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                                            //console.log(downloadURL)
                                            let formDataaa = this.state.formData;
                                            formDataaa.imageOrVideoDownloadURL = downloadURL;
                                            formDataaa.imageOrVideoFileSize = fileSize;
                                            formDataaa.imageOrVideoFileType = imageOrVideoFileType;
                                            formDataaa.videoThumbnailUrl = videoThumbUrl;
                                            this.setState(formDataaa);
                                            this.setState({
                                                isFileUploading: false
                                            })

                                            this.insertPostData(selectedUsers, 0, eventRefId, withOutAdminUsers);
                                        });
                                    });
                            });
                        });
                    // video thumbnel end
                }
                else { // Image
                    const metadata = { contentType: fileType };
                    //var fileExt = file.name.substr(file.name.lastIndexOf('.') + 1);
                    var uploadTask = storageRef.child(`${adminUserPhoneNo}/users/events/eventImage_${eventRefId}` + file.name).put(file, metadata);

                    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                        (snapshot) => {
                            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log('Upload is ' + progress + '% done');
                            this.setState({
                                isFileUploadingPercentage: parseInt(progress)
                            })
                            switch (snapshot.state) {
                                case firebase.storage.TaskState.PAUSED: // or 'paused'
                                    console.log('Upload is paused');
                                    break;
                                case firebase.storage.TaskState.RUNNING: // or 'running'
                                    console.log('Upload is running');
                                    break;
                            }
                        }, (error) => {

                            // A full list of error codes is available at
                            // https://firebase.google.com/docs/storage/web/handle-errors
                            switch (error.code) {

                            }
                        }, () => {
                            // Upload completed successfully, now we can get the download URL
                            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                                //console.log(downloadURL)
                                let formDataaa = this.state.formData;
                                formDataaa.imageOrVideoDownloadURL = downloadURL;
                                formDataaa.imageOrVideoFileSize = fileSize;
                                formDataaa.imageOrVideoFileType = imageOrVideoFileType
                                this.setState(formDataaa);
                                this.setState({
                                    isFileUploading: false
                                })

                                this.insertPostData(selectedUsers, 0, eventRefId, withOutAdminUsers);
                            });
                        });
                }
            }
            else {
                this.insertPostData(selectedUsers, 0, eventRefId, withOutAdminUsers);
            }
        }
        else {
            alert('Please select at least one user');
        }
    }

    insertPostData = (selectedUsers, selectedUsersIndex, eventRefId, withOutAdminUsers) => {
        let currentUser = selectedUsers[selectedUsersIndex];
        /*** STEP 2 ***/

        let dictChildInvitation = {
            invitationStatus: 1,
            responseVia: 1,
            user_name: currentUser.user_name,
            profileImage: currentUser.profileImage,
            isOwner: true,
            contactID: '1'
        }

        firebase.ref().child('inviteeList').child(eventRefId).child(currentUser.phone_number).set(dictChildInvitation).then(() => {
            /*** STEP 3 ***/

            let isRSVP = false;
            let isDIRECT = false;

            if ((this.state.formData.postTypeArr.length == 1) && ($.inArray("rsvp", this.state.formData.postTypeArr) >= 0)) {
                isRSVP = true;
                isDIRECT = false;
            }
            else if ((this.state.formData.postTypeArr.length == 1) && ($.inArray("direct", this.state.formData.postTypeArr) >= 0)) {
                isRSVP = false;
                isDIRECT = true;
            }
            else if (this.state.formData.postTypeArr.length > 1) {
                isRSVP = true;
                isDIRECT = true;
            }

            // For Link
            let isLink = false;
            if (this.state.formData.link != '') {
                isLink = true;
            }

            let linkInfo = {};

            if (this.state.urlMetaData.url != '') {
                linkInfo.displayUrl = this.state.urlMetaData.url
                linkInfo.url = this.state.urlMetaData.url
                linkInfo.sortUrl = this.state.urlMetaData.bitlyUrl
            }
            if (this.state.urlMetaData.image != '') {
                linkInfo.image = this.state.urlMetaData.image
            }
            if (this.state.urlMetaData.title != '') {
                linkInfo.title = this.state.urlMetaData.title
            }
            if (this.state.urlMetaData.description != '') {
                linkInfo.description = this.state.urlMetaData.description
            }

            // For is visible
            let isVisible = false;
            if (currentUser.phone_number == adminUserPhoneNo) {
                isVisible = true;
            }
            else if ((this.state.formData.postTypeArr.length == 1) && ($.inArray("direct", this.state.formData.postTypeArr) >= 0)) {
                isVisible = true;
            }

            // For invitees
            let inviteesData = {
                accepted_count: 0,
                acceptedUsers: {},
                invitation_count: selectedUsers.length,
                invitedUsers: {}
            };

            if (isRSVP) {
                inviteesData.accepted_count = 1;
            }
            else {
                inviteesData.accepted_count = selectedUsers.length
            }

            if (isRSVP) {
                inviteesData.acceptedUsers[adminUserPhoneNo] = true;
            }
            else {
                let acceptedUsers = {};

                selectedUsers.forEach(function (eachUser) {
                    acceptedUsers[eachUser.phone_number] = true;
                });

                inviteesData.acceptedUsers = acceptedUsers;
            }

            let invitedUsers = {};
            selectedUsers.forEach(function (eachUser) {
                invitedUsers[eachUser.phone_number] = true;
            });

            inviteesData.invitedUsers = invitedUsers;

            const timeLineData = {
                eventId: eventRefId,
                invitees: inviteesData,
                createdOn: Math.round(new Date().getTime() / 1000).toString(),
                eventTitle: this.state.formData.title,
                desc: this.state.formData.description,
                type: 1,
                attachmentUrl: this.state.formData.imageOrVideoDownloadURL,
                imagesize: this.state.formData.imageOrVideoFileSize,
                attachmentType: this.state.formData.imageOrVideoFileType,
                thumbnailUrl: this.state.formData.videoThumbnailUrl,
                isRead: false,
                isRSVP: isRSVP,
                isDIRECT: isDIRECT,
                isLink: isLink,
                linkInfo: linkInfo,
                isVisible: isVisible,
                user: {
                    user_name: this.state.adminUserData.user_name,
                    profileImage: this.state.adminUserData.profileImage,
                    userID: this.state.adminUserData.phone_number
                },
                owner: {
                    user_name: this.state.adminUserData.user_name,
                    profileImage: this.state.adminUserData.profileImage,
                    userID: this.state.adminUserData.phone_number
                }
            }

            console.log(timeLineData)

            firebase.ref().child('timelines').child(currentUser.phone_number).child(eventRefId).set(timeLineData).then(() => {
                /*** STEP 4 ***/
                firebase.ref().child('users').child(currentUser.phone_number).child('invited_events').child(eventRefId).set(true).then(() => {
                    /*** STEP 5 ***/
                    let eventdate = '';
                    if (this.state.formData.eventDateAndTime != '') {
                        eventdate = (new Date(this.state.formData.eventDateAndTime).getTime() / 1000).toString();
                    }

                    const historyData = {
                        createdOn: Math.round(new Date().getTime() / 1000).toString(),
                        eventdate: eventdate,
                        invitees: inviteesData,
                        eventTitle: this.state.formData.title,
                        isRSVP: isRSVP,
                        isDIRECT: isDIRECT,
                        desc: this.state.formData.description,
                        isLink: isLink,
                        linkInfo: linkInfo,
                        eventLocation: this.state.formData.location,
                        isEdited: false,
                        isVisible: isVisible,
                        isPostCreatedFromAdminPannel: true,
                        user: {
                            user_name: this.state.adminUserData.user_name,
                            profileImage: this.state.adminUserData.profileImage,
                            userID: this.state.adminUserData.phone_number
                        },
                        owner: {
                            user_name: this.state.adminUserData.user_name,
                            profileImage: this.state.adminUserData.profileImage,
                            userID: this.state.adminUserData.phone_number
                        }
                    }

                    //console.log(historyData);
                    //return false;

                    firebase.ref().child('history').child(currentUser.phone_number).child(eventRefId).set(historyData).then(() => {
                        if (selectedUsersIndex + 1 < selectedUsers.length) {
                            this.setState({
                                totalInvitedUsersCount: selectedUsersIndex + 1
                            })
                            this.insertPostData(selectedUsers, selectedUsersIndex + 1, eventRefId, withOutAdminUsers);
                        }
                        else {

                            //Create Post Data (Dibya)
                            withOutAdminUsers.unshift(this.state.adminUserData);
                            let postInviteeList = [];
                            _.each(withOutAdminUsers, (_eachUser) => {
                                let eachUser = {
                                    user_name: _eachUser.user_name,
                                    contactNumber: _eachUser.phone_number,
                                    profileImage: _eachUser.profileImage,
                                    contactID: '1'
                                };
                                postInviteeList.push(eachUser);
                            });

                            let location = {
                                title: '',
                                subtitle: '',
                                lat: '',
                                long: ''
                            };

                            if (this.state.formData.location != '') {
                                location.title = this.state.formData.locationData.label;
                                location.subtitle = this.state.formData.locationData.label;
                                location.lat = this.state.formData.locationData.location.lat;
                                location.long = this.state.formData.locationData.location.lng;
                            }

                            let postDataParameters = {
                                userID: adminUserPhoneNo,
                                user: {
                                    user_name: this.state.adminUserData.user_name,
                                    profileImage: this.state.adminUserData.profileImage,
                                    userID: this.state.adminUserData.phone_number
                                },
                                owner: {
                                    user_name: this.state.adminUserData.user_name,
                                    profileImage: this.state.adminUserData.profileImage,
                                    userID: this.state.adminUserData.phone_number
                                },
                                linkInfo: linkInfo,
                                createdOn: Math.round(new Date().getTime() / 1000).toString(),
                                eventDescription: this.state.formData.description,
                                desc: this.state.formData.description,
                                eventId: eventRefId,
                                eventTitle: this.state.formData.title,
                                isReinvited: false,
                                isEdited: false,
                                iseditedEvent: false,
                                location: location,
                                eventDate: eventdate,
                                //inviteeList: postInviteeList,
                                isDirect: isDIRECT,
                                isRSVP: isRSVP,
                                eventLocation: this.state.formData.location,
                                isVisible: isRSVP ? false : true,
                                type: 1,
                                isRead: false,
                                attachmentUrl: this.state.formData.imageOrVideoDownloadURL,
                                imagesize: this.state.formData.imageOrVideoFileSize,
                                attachmentType: this.state.formData.imageOrVideoFileType,
                                isAdmin: true
                            };

                            if (this.state.formData.imageOrVideoFileType == 2) {
                                postDataParameters.thumbnailUrl = this.state.formData.videoThumbnailUrl;
                            }

                            this.finalEventSubmit(eventRefId, inviteesData, postDataParameters, postInviteeList);
                        }
                    }).catch(error => {
                        console.log(error)
                    });

                });
            });
        });

        console.log('Event Id=>>>>' + eventRefId);

    }

    finalEventSubmit = (eventRefId, inviteesData, postDataParameters, postInviteeList) => {
        /*** STEP 6 ***/
        let eventdate = '';
        if (this.state.formData.eventDateAndTime != '') {
            eventdate = (new Date(this.state.formData.eventDateAndTime).getTime() / 1000).toString();
        }

        let isRSVP = false;
        let isDIRECT = false;

        if ((this.state.formData.postTypeArr.length == 1) && ($.inArray("rsvp", this.state.formData.postTypeArr) >= 0)) {
            isRSVP = true;
            isDIRECT = false;
        }
        else if ((this.state.formData.postTypeArr.length == 1) && ($.inArray("direct", this.state.formData.postTypeArr) >= 0)) {
            isRSVP = false;
            isDIRECT = true;
        }
        else if (this.state.formData.postTypeArr.length > 1) {
            isRSVP = true;
            isDIRECT = true;
        }

        // For Link
        let isLink = false;
        if (this.state.formData.link != '') {
            isLink = true;
        }

        // let linkInfo = {
        //     description: this.state.urlMetaData.description,
        //     displayUrl: this.state.urlMetaData.url,
        //     image: this.state.urlMetaData.image,
        //     title: this.state.urlMetaData.title,
        //     url: this.state.urlMetaData.url,
        //     sortUrl: this.state.urlMetaData.bitlyUrl,
        // }


        let linkInfo = {};

        if (this.state.formData.link != '') {
            if (this.state.urlMetaData.url != '') {
                linkInfo.displayUrl = this.state.urlMetaData.url
                linkInfo.url = this.state.urlMetaData.url
                linkInfo.sortUrl = this.state.urlMetaData.bitlyUrl
            }
            if (this.state.urlMetaData.image != '') {
                linkInfo.image = this.state.urlMetaData.image
            }
            if (this.state.urlMetaData.title != '') {
                linkInfo.title = this.state.urlMetaData.title
            }
            if (this.state.urlMetaData.description != '') {
                linkInfo.description = this.state.urlMetaData.description
            }
        }

        const eventData = {
            createdOn: Math.round(new Date().getTime() / 1000).toString(),
            eventTitle: this.state.formData.title,
            desc: this.state.formData.description,
            eventdate: eventdate,
            isDIRECT: isDIRECT,
            isRSVP: isRSVP,
            isLink: isLink,
            linkInfo: linkInfo,
            invitees: inviteesData,
            owner: {
                user_name: this.state.adminUserData.user_name,
                profileImage: this.state.adminUserData.profileImage,
                userID: this.state.adminUserData.phone_number
            }
        }

        if (this.state.formData.location != '') {
            eventData.location = this.state.formData.locationData.label;
            eventData.lat = this.state.formData.locationData.location.lat.toString();
            eventData.long = this.state.formData.locationData.location.lng.toString();
        }

        if ((this.state.formData.imageOrVideoFileType == 1 || this.state.formData.imageOrVideoFileType == 2) && this.state.formData.imageOrVideoDownloadURL != '') {
            eventData.attachmentUrl = this.state.formData.imageOrVideoDownloadURL;
            eventData.attachmentType = this.state.formData.imageOrVideoFileType;
        }

        if (this.state.formData.imageOrVideoFileType == 2) {
            eventData.thumbnailUrl = this.state.formData.videoThumbnailUrl;
        }

        firebase.ref().child('events').child(eventRefId).set(eventData).then(() => {
            //Done
            let chunkInviteeList = this.chunk(postInviteeList, 50);

            firebase.ref().child('adminEventInviteeList').child(eventRefId).set(postInviteeList).then(() => {
                axios.post(fireBaseCloudFunctionBaseUrl + '/CreateOrEditEvent', qs.stringify(postDataParameters));

                setTimeout(() => {
                    this.setState({
                        postSubmitedSuccessfuly: true
                    }, ()=>{
                        setTimeout(() => {
                            this.setState({
                                redirect: true
                            })
                        }, 3000)
                    })
                }, 4000)
            });


            //console.log(qs.stringify(postDataParameters))
            // console.log(postDataParameters.inviteeList)

            // let chunkInviteeList = this.chunk(postInviteeList, 500);

            // console.log(chunkInviteeList);
            // alert(sizeof(chunkInviteeList));

            // _.each(chunkInviteeList, (_eachChunkInvitee) => {
            //     alert('jjj22')
            //     postDataParameters.inviteeList = _eachChunkInvitee;
            //     console.log(JSON.stringify(postDataParameters))

            //console.log(qs.stringify(postDataParameters));
            //console.log(qs.stringify(postDataParameters).length);
            //const config = { };


            // let config = {
            //     headers: {
            //         'Content-Type': 'application/json',
            //     }
            // };
            // axios.post(fireBaseCloudFunctionBaseUrl + '/CreateOrEditEvent', postDataParameters);
            //});

            //console.log(this.chunk(postInviteeList, 50));

        });
    }

    chunk = (arr, size) =>
        Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
            arr.slice(i * size, i * size + size)
        );


    render() {

        let step1Style = {
            display: 'none'
        }

        if (this.state.currentStep == 'step1') {
            step1Style.display = 'block'
        }

        let step2Style = {
            display: 'none'
        }

        if (this.state.currentStep == 'step2') {
            step2Style.display = 'block'
        }

        let selectedUsers = this.state.fileteredUsers.filter((user) => {
            return user.isChecked == true;
        });

        if (this.state.redirect) {
            return <Redirect to={'/'} />
        }


        return (
            <React.Fragment>
                <Header />
                <LeftPanel />
                <div id="main-container">


                    {this.state.isUserSubmitting &&
                        <div id="overlay" className="transparent">
                            <div className="custom-popup width-100 popup_content popup_content_visible postEventLoader">
                                <div className="padding-md">
                                    {!this.state.isFileUploading && !this.state.postSubmitedSuccessfuly &&
                                        <h4 className="m-top-none"> Please wait...</h4>
                                    }

                                    {this.state.isFileUploading && !this.state.postSubmitedSuccessfuly &&
                                        <h4 className="m-top-none">Image or Video uploading ({this.state.isFileUploadingPercentage}%) ...</h4>
                                    }

                                    {this.state.postSubmitedSuccessfuly &&
                                        <h4 className="m-top-none" style={{ color: 'green' }}>Post created successfully</h4>
                                    }
                                </div>
                                {!this.state.postSubmitedSuccessfuly &&
                                    <div style={{ fontSize: 28, color: 'chocolate' }}><i className="fa fa-circle-o-notch fa-spin"></i></div>
                                }
                            </div>
                        </div>
                    }

                    <div className="main-header clearfix">
                        <div className="page-title">
                            <h3 className="no-margin">Bulk Post</h3>
                        </div>
                    </div>
                    <div className="padding-md">

                        <div className="row" style={step1Style}>
                            <div className="col-md-6">
                                <div className="panel panel-default">

                                    <div className="panel-heading">
                                        &nbsp;
                                    </div>
                                    <div className="panel-body">

                                        <div className="form-group">
                                            <label className="control-label">Post Description <span style={{ color: 'red' }}>*</span></label>
                                            <textarea className="form-control parsley-validated" maxLength={140} onChange={this.handleChangeFor('title')} placeholder="What are you doing or thinking?" rows={3} data-required="true" defaultValue={this.state.formData.title} />

                                            {this.state.formData.isDescriptionError &&
                                                <div style={{ color: '#fc8675' }}>Post description is required.</div>
                                            }

                                        </div>
                                        <div className="form-group">
                                            <label className="control-label">Image/ Video (jpg, jpeg, png, mp4)</label>
                                            <input type="file" id="file_multi_videoor_image" onChange={this.imageOrVideoChange} className="form-control input-sm parsley-validated" accept="image/jpg,image/png,image/jpeg,video/mp4" />
                                        </div>
                                        <div className="form-group">
                                            <label className="control-label">Location</label>
                                            <Geosuggest
                                                placeholder={''}
                                                onSuggestSelect={this.onLocationSuggestSelect}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="control-label">Event date & time</label>
                                            <Datetime dateFormat="YYYY-MM-DD" timeFormat={true} readonly={true} onChange={this.onDateChange} placeholder="Event date & time" />
                                            {this.state.formData.eventDateAndTimeError &&
                                                <div style={{ color: '#fc8675' }}>{this.state.formData.eventDateAndTimeErrorTxt}</div>
                                            }

                                        </div>
                                        <div className="form-group">
                                            <label className="control-label">Link</label>
                                            <input type="text" onChange={this.handleChangeFor('link')} onBlur={this.getMetaDataFromUrl} className="form-control input-sm parsley-validated" defaultValue={this.state.formData.link} />
                                            {this.state.formData.isLinkError &&
                                                <div style={{ color: '#fc8675' }}>Please enter valid link.</div>
                                            }
                                        </div>

                                        <div className="form-group">
                                            <label className="control-label">Post Type </label>
                                            <div>
                                                <label className="label-checkbox inline" style={{ marginLeft: '-15px' }}>
                                                    <input type="checkbox" name="inline-checkbox"
                                                        value="direct"
                                                        //checked={this.state.formData.postType == 'direct'}
                                                        onChange={this.handleChangeFor('postType')}
                                                    />
                                                    <span className="custom-checkbox" />
                                                    Direct
                                                </label>
                                                <label className="label-checkbox inline">
                                                    <input type="checkbox" name="inline-checkbox"
                                                        value="rsvp"
                                                        //checked={this.state.formData.postType == 'rsvp'}
                                                        onChange={this.handleChangeFor('postType')}
                                                    />
                                                    <span className="custom-checkbox" />
                                                    RSVP
                                                </label>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label className="control-label">Invitees </label>
                                            <div>
                                                <label className="label-radio inline" style={{ marginLeft: '-15px' }}>
                                                    <input type="radio" name="inline-userType"
                                                        value="all"
                                                        checked={this.state.filterUserType == 'all'}
                                                        onChange={() => this.handleSearch('filterUserType', 'all')}
                                                    //Dibya alluser
                                                    />
                                                    <span className="custom-radio" />
                                                    All users
                                                </label>
                                                <label className="label-radio inline">
                                                    <input type="radio" name="inline-userType"
                                                        value="filtered"
                                                        checked={this.state.filterUserType == 'filtered'}
                                                        onChange={() => this.handleSearch('filterUserType', 'filtered')}
                                                    // checked={this.state.formData.postType == 'rsvp'}
                                                    // onChange={this.handleChangeFor('postType')}
                                                    />
                                                    <span className="custom-radio" />
                                                    Specific users
                                                </label>
                                                <lable className="label-radio inline">

                                                    {this.state.isFetchingUsers &&
                                                        <span className="label label-info pull-right" style={{ fontSize: 12, lineHeight: '15px' }}>
                                                            <i className="fa fa-circle-o-notch fa-spin"></i> Loading users...
                                                    </span>
                                                    }
                                                    {!this.state.isFetchingUsers &&
                                                        <span className="label label-info pull-right" style={{ fontSize: 12, lineHeight: '15px' }}>
                                                            {selectedUsers.length} User(s)
                                                    </span>
                                                    }
                                                </lable>
                                            </div>
                                        </div>

                                        {this.state.filterUserType == 'filtered' &&


                                            <div className="row">
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label className="control-label">Country</label>
                                                        <select className="form-control" id="countrySearchVal" onChange={() => this.handleSearch('doFilter')}>
                                                            <option value="">All</option>
                                                            {this.state.filterCountry.map((country, index) => (
                                                                <option key={country} value={country}>{country}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-1" style={{ width: '1%' }}>
                                                    <label className="control-label">&nbsp;</label>
                                                    <div style={{ marginTop: 8 }}>And</div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label className="control-label">State</label>
                                                        <select className="form-control" id="localitySearchVal" onChange={() => this.handleSearch('doFilter')}>
                                                            <option value="">All</option>
                                                            {this.state.filterLocality.map((locality, index) => (
                                                                <option key={locality} value={locality}>{locality}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-1" style={{ width: '1%' }}>
                                                    <label className="control-label">&nbsp;</label>
                                                    <div style={{ marginTop: 8 }}>And</div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label className="control-label">City</label>
                                                        <select className="form-control" id="citySearchVal" onChange={() => this.handleSearch('doFilter')}>
                                                            <option value="">All</option>
                                                            {this.state.filterCity.map((city, index) => (
                                                                <option key={city} value={city}>{city}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-1" style={{ width: '1%' }}>
                                                    <label className="control-label">&nbsp;</label>
                                                    <div style={{ marginTop: 8 }}>And</div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label className="control-label">Time Zone</label>
                                                        <select className="form-control" id="timiZoneSearchVal" onChange={() => this.handleSearch('doFilter')}>
                                                            <option value="">All</option>
                                                            {this.state.filterTimeZone.map((timezone, index) => (
                                                                <option value={timezone}>{timezone}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                    <div className="panel-footer text-right">
                                        {!this.state.isFetchingUsers && !this.state.formData.isLinkGettingMetaData &&
                                            <button type="button" className="btn btn-success" onClick={this.submitFirstStep}>Submit</button>
                                        }
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="panel panel-default" style={{ minHeight: 565 }}>

                                    <div className="panel-heading">
                                        Preview
                                        {this.state.formData.isLinkGettingMetaData &&
                                            <span style={{ float: 'right', fontSize: 18, color: 'green' }}><i className="fa fa-circle-o-notch fa-spin"></i></span>
                                        }
                                    </div>
                                    <div className="panel-body">
                                        <div className="row">

                                            {/* {this.state.renderImageOrVideoPreview != null &&
                                                <div className="col-md-6">
                                                    <div className="form-group">

                                                        <label className="control-label">Image/ Video Preview</label>
                                                        <div className="card">


                                                            {this.state.renderImageOrVideoPreview == 'video' &&
                                                                <a className="img-card" href="javascript:void(0);" style={{ cursor: 'auto' }}>
                                                                    <video loop autoPlay style={{ width: '100%' }}>
                                                                        <source id="video_here" src={''} type="video/mp4" />Your browser does not support the video tag. I suggest you upgrade your browser.
                                                                    </video>
                                                                </a>
                                                            }

                                                            {this.state.renderImageOrVideoPreview == 'image' &&
                                                                <a className="img-card" href="javascript:void(0);">
                                                                    <img id="image-preview" src="" />
                                                                </a>
                                                            } 
                                                            <div className="card-read-more">
                                                                <a href="javascript::void(0);" onClick={this.removeImageOrVideo} className="btn btn-link btn-block">
                                                                    <span><i className="fa fa-trash-o" ></i></span> Remove
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            } */}

                                            {/* {this.state.renderLinkPreview && */}
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    {/* <label className="control-label">Link Preview</label> */}

                                                    <div className="card" style={{ minHeight: 245 }}>

                                                        <div className="card-content">
                                                            <p>
                                                                {this.state.formData.description.split("\n").map(function (item) {
                                                                    return (

                                                                        <span>
                                                                            {item}
                                                                            <br />
                                                                        </span>
                                                                    )
                                                                })}
                                                            </p>
                                                        </div>

                                                        {this.state.videoFile != '' &&
                                                            <div style={{ display: 'none' }}>
                                                                <VideoThumbnail
                                                                    videoUrl={this.state.videoFile}
                                                                    thumbnailHandler={(thumbnail) => this.createThumbnalVideo(thumbnail)}
                                                                />
                                                            </div>
                                                        }

                                                        {this.state.renderImageOrVideoPreview == 'video' &&
                                                            <a className="img-card" href="javascript:void(0);" style={{ cursor: 'auto', marginBottom: 10 }}>
                                                                <video loop autoPlay style={{ width: '100%' }}>
                                                                    <source id="video_here" src={''} type="video/mp4" />Your browser does not support the video tag. I suggest you upgrade your browser.
                                                                </video>
                                                            </a>
                                                        }

                                                        {this.state.renderImageOrVideoPreview == 'image' &&
                                                            <a className="img-card" href="javascript:void(0);" style={{ marginBottom: 10 }}>
                                                                <img id="image-preview" src="" />
                                                            </a>
                                                        }

                                                        {this.state.renderLinkPreview && this.state.formData.link != '' &&
                                                            <React.Fragment>
                                                                {this.state.urlMetaData.image != '' &&
                                                                    <a className="img-card" href="javascript:void(0);">
                                                                        <img src={this.state.urlMetaData.image} />
                                                                    </a>
                                                                }
                                                                <div className="card-content" style={{ background: '#f5f5f5', marginTop: 5 }}>
                                                                    <h4 className="card-title">
                                                                        <a href="javascript:void(0);" style={{ cursor: 'auto' }}>
                                                                            {this.state.urlMetaData.title}
                                                                        </a>
                                                                    </h4>
                                                                    <p>
                                                                        {this.state.urlMetaData.description}
                                                                    </p>

                                                                    <div style={{ color: '#4459ca' }}>
                                                                        {this.state.urlMetaData.bitlyUrl}
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                            {/* } */}

                                            {/* {this.state.renderImageOrVideoPreview == null && !this.state.renderLinkPreview &&
                                                <div style={{ textAlign: 'center', marginTop: '20%', color: '#469c46', fontSize: 15 }}><b>There has no data to show preview</b></div>
                                            } */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/*<div style={step2Style}>
                             <div className="row">
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label className="control-label">Country</label>
                                        <select className="form-control" id="countrySearchVal" onChange={this.handleSearch}>
                                            <option value="">Select</option>
                                            {this.state.filterCountry.map((country, index) => (
                                                <option key={country} value={country}>{country}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label className="control-label">State</label>
                                        <select className="form-control" id="localitySearchVal" onChange={this.handleSearch}>
                                            <option value="">Select</option>
                                            {this.state.filterLocality.map((locality, index) => (
                                                <option key={locality} value={locality}>{locality}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label className="control-label">City</label>
                                        <select className="form-control" id="citySearchVal" onChange={this.handleSearch}>
                                            <option value="">Select</option>
                                            {this.state.filterCity.map((city, index) => (
                                                <option key={city} value={city}>{city}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label className="control-label">Time Zone</label>
                                        <select className="form-control" id="timiZoneSearchVal" onChange={this.handleSearch}>
                                            <option value="">Select</option>
                                            {this.state.filterTimeZone.map((timezone, index) => (
                                                <option value={timezone}>{timezone}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div> 

                            <div className="panel panel-default table-responsive">
                                <div className="panel-heading">
                                    Users List
                                    <span className="label label-info pull-right" style={{ fontSize: 12, lineHeight: '15px' }}>{selectedUsers.length} User(s) Selected</span>
                                </div>
                    
                                <div style={{ height: 560, overflow: 'auto' }}>
                                    <table className="table table-striped" >
                                        <thead>
                                            <tr>
                                                <th>
                                                    <label className="label-checkbox">
                                                        <input type="checkbox" onClick={this.handleSelectAllUsers} value="checkedall" />
                                                        <span className="custom-checkbox" />
                                                    </label>
                                                </th>
                                                <th>Phone No.</th>
                                                <th>Name</th>
                                                <th>Image</th>
                                                <th>Type</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {this.state.fileteredUsers.map((user, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <label className="label-checkbox">
                                                            <input type="checkbox" className="chk-row" onClick={this.handleSelectUnselectUser} value={user.phone_number} checked={user.isChecked} />
                                                            <span className="custom-checkbox" />
                                                        </label>
                                                    </td>
                                                    <td>{user.phone_number}</td>
                                                    <td>{user.user_name}</td>
                                                    <td>
                                                        {user.profileImage != '' &&
                                                            <div className="user-block clearfix">
                                                                <img style={{ width: 50 }} src={user.profileImage} />
                                                            </div>
                                                        }

                                                        {user.profileImage == '' &&
                                                            <div className="user-block clearfix">
                                                                <img style={{ width: 50 }} src={'https://www.5dariyanews.com/images/no-profile-image.gif'} />
                                                            </div>
                                                        }
                                                    </td>
                                                    <td>
                                                        {user.createdOn != '' &&
                                                            <span className="label label-success">App user</span>
                                                        }
                                                        {user.createdOn == '' &&
                                                            <span className="label label-danger">Non app user</span>
                                                        }
                                                    </td>
                                                    <td>
                                                        {user.createdOn != '' &&
                                                            <Moment format="YYYY/MM/DD">
                                                                {user.createdOn * 1000}
                                                            </Moment>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                </div>
                                <div className="panel-footer clearfix" style={{ textAlign: 'right' }}>
                                 
                                    <a className="btn btn-primary" onClick={this.goPrev} style={{ marginRight: 10 }}>&lt;&lt;Prev</a>
                                    <a className="btn btn-success" onClick={this.submitStep2}>Submit</a>
                                </div>
                            </div>

                        </div>*/}



                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}

export default CreatePost