import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { signOut } from '../../store/actions/authActions'
import logoImage from '../../assets/images/logo.png';

class Header extends Component {

  constructor(props){
    super(props)
    this.state = {
      profileMenuOpen: false
    }
  }

  showHideProfileMenu = () =>{
    this.setState({
      profileMenuOpen: !this.state.profileMenuOpen
    })
  }

  appLogout = () =>{
    this.props.signOut()
  }

  render() {

    let profileOptionClass = "profile dropdown ";
    if(this.state.profileMenuOpen){
      profileOptionClass = profileOptionClass+' open'
    }

    return (
      <div id="top-nav" className="fixed skin-6">

        <Link to="/" className="brand">
          <span style={{marginRight:3}}><img style={{width:27}} src={logoImage} /></span><span>InsideSeat</span>
        </Link>
       
        {/* <button type="button" className="navbar-toggle pull-left" id="sidebarToggle">
          <span className="icon-bar" />
          <span className="icon-bar" />
          <span className="icon-bar" />
        </button>
        <button type="button" className="navbar-toggle pull-left hide-menu" id="menuToggle">
          <span className="icon-bar" />
          <span className="icon-bar" />
          <span className="icon-bar" />
        </button> */}
        <ul className="nav-notification clearfix">
          <li className={profileOptionClass}>
            <a className="dropdown-toggle" data-toggle="dropdown" onClick={this.showHideProfileMenu}>
              <strong>Admin </strong>
              <span><i className="fa fa-chevron-down" /></span>
            </a>
            <ul className="dropdown-menu">
              <li><a tabIndex={-1} className="main-link logoutConfirm_open" onClick={this.appLogout}><i className="fa fa-lock fa-lg" /> Log out</a></li>
            </ul>
          </li>
        </ul>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    
  }
}

const mapDispatchToProps = (dispatch)=> {
  return {
    signOut: () => dispatch(signOut())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)