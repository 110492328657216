import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class LeftPanel extends Component {
  render() {
    return (
      <aside className="fixed skin-6">
        <div className="sidebar-inner scrollable-sidebar">
          
          <div className="main-menu">
            <ul>
              <li className="">
                <Link to="/">
                  <span className="menu-icon">
                    <i className="fa fa-desktop fa-lg" />
                  </span>
                  <span className="text">
                    Dashboard
                  </span>
                  <span className="menu-hover" />
                  </Link>
              </li>
              <li className="openable open">
                <Link to="/message-category">
                  <span className="menu-icon">
                    <i className="fa fa-file-text fa-lg" />
                  </span>
                  <span className="text">
                    Manage Content
                  </span>
                  <span className="menu-hover" />
                </Link>
              </li>
              <li className="openable open">
                <Link to="/create-post">
                  <span className="menu-icon">
                    <i className="fa fa-file-text fa-lg" />
                  </span>
                  <span className="text">
                    Bulk Post
                  </span>
                  <span className="menu-hover" />
                </Link>
              </li>
            </ul>
          </div>{/* /main-menu */}
        </div>{/* /sidebar-inner */}
      </aside>
    )
  }
}
export default LeftPanel;