import React from 'react'
import { 
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom'
import ScrollToTop from './ScrollToTop'
import PrivateRoute from './PrivateRoute'
//const Dashboard = lazy(() => import('./../components/dashboard/Dashboard'));
import Login from './../components/auth/Login'
import Dashboard from './../components/dashboard/Dashboard'
import MessageMainCategory from './../components/messages/MessageMainCategory'
import MessageSubCategory from './../components/messages/MessageSubCategory'
import MessageEditMain from './../components/messages/messageEdit/MessageEditMain'
import CreatePost from './../components/post/CreatePost'

const appRouter = () => (
    <Router>
        <ScrollToTop>
            <Switch>
                <Route path="/login" component={Login} />
                <PrivateRoute exact={true} path="/" component={Dashboard} />
                <PrivateRoute exact={true} path="/message-category" component={MessageMainCategory} />
                <PrivateRoute exact={true} path="/message-category/:idval" component={MessageSubCategory} />
                <PrivateRoute exact={true} path="/message-edit/:categoryId/:subCategotyId" component={MessageEditMain} />
                <PrivateRoute exact={true} path="/create-post" component={CreatePost} />
            </Switch>
        </ScrollToTop>
    </Router>
);

export default appRouter;