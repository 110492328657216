import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import Header from './../../includes/Header'
import LeftPanel from './../../includes/LeftPanel'
import Footer from './../../includes/Footer'

import MessageCreatePostEdit from './MessageCreatePostEdit'
import MessageDeletePostEdit from './MessageDeletePostEdit'
import MessageEditPostEdit from './MessageEditPostEdit'
import MessageEditPostContentEdit from './MessageEditPostContentEdit'
import MessageEditPostLocationDateTimeEdit from './MessageEditPostLocationDateTimeEdit'
import MessagePostLikeCommentReplyEdit from './MessagePostLikeCommentReplyEdit'
import MessageReminderEdit from './MessageReminderEdit'
import MessageRSVPResponseEdit from './MessageRSVPResponseEdit'
import MessageSendOtpEdit from './MessageSendOtpEdit'
import MessageMentionedUserEdit from './MessageMentionedUserEdit'

class MessageEditMain extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    renderSwitch = () => {

        const mainCategoryId = this.props.match.params.categoryId;
        const subCategotyId = this.props.match.params.subCategotyId;

        const data = {
            mainCategoryId,
            subCategotyId
        }
        
        switch (mainCategoryId) {
            case 'createPost':
                return <MessageCreatePostEdit data={data} />
            case 'deletePost':
                return <MessageDeletePostEdit data={data} />
            case 'editPost':
                return <MessageEditPostEdit data={data} />
            case 'editPostContent':
                return <MessageEditPostContentEdit data={data} />
            case 'editPostLocationDateTime':
                return <MessageEditPostLocationDateTimeEdit data={data} />
            case 'postLikeCommentReply':
                return <MessagePostLikeCommentReplyEdit data={data} />
            case 'reminder':
                return <MessageReminderEdit data={data} />
            case 'RSVPResponse':
                return <MessageRSVPResponseEdit data={data} />
            case 'sendOtp':
                return <MessageSendOtpEdit data={data} />
            case 'mentionedUser':
                return <MessageMentionedUserEdit data={data} />
            default:
                return <div>Data not found</div>
        }
    }


    render() {
        return (
            <React.Fragment>
                <Header />
                <LeftPanel />
                <div id="main-container">

                    <div className="main-header clearfix">
                        <div className="page-title" style={{width: '100%'}}>
                            <h3 className="no-margin">Edit Message</h3>
                            <Link to={'/message-category/'+this.props.match.params.categoryId}>
                                <button type="button" style={{float: 'right', marginTop: -25}} className="btn btn-info btn-sm"><i className="fa fa-reply" aria-hidden="true"></i> Back</button>
                            </Link>
                        </div>{/* /page-title */}
                    </div>{/* /main-header */}

                    <div className="padding-md">
                        { this.renderSwitch() }
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}


export default withRouter(MessageEditMain)