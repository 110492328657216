import React, { Component } from 'react'
class MessageNote extends Component {
  render() {
    return (
        <div style={{color: '#fc8675',marginBottom: 15}}>
            <b>Please replace tags &lt;&gt; if required from the list bellow.<br></br> &lt;Event Title&gt;<br></br>  &lt;User Namee&gt;<br></br> &lt;Download Link&gt;<br></br> &lt;Event Link&gt; <br></br> &lt;Invitee Name&gt;</b>
        </div>
    )
  }
}
export default MessageNote;