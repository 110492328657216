import React, { Component } from 'react'
class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="row">
          <div className="col-sm-6">
            <span className="footer-brand">
              <strong className="text-danger">InsideSeat</strong> Admin
            </span>
            <p className="no-margin">
              © 2019 <strong>InsideSeat Admin</strong>. ALL Rights Reserved.
            </p>
          </div>{/* /.col */}
        </div>{/* /.row*/}
      </footer>
    )
  }
}
export default Footer;