import React from 'react'
import { connect } from 'react-redux'
import {
    Route,
    withRouter,
    Redirect
} from 'react-router-dom'

function PrivateRoute({
    auth,
    component: Component,
    ...rest
  }) {
    return (
      <Route
        {...rest}
        render={props =>
          auth.uid? (
            <Component {...props} {...rest} />
          ) : (
            <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
          )
        }
      />
    );
}

const stateMapToProps = (state) => {
    return {
      auth: state.firebase.auth
    }
}

export default connect(stateMapToProps)(withRouter(PrivateRoute));