import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';

// Replace this with your own config details
var devConfig = {
    apiKey: "AIzaSyBrT_4dirgkBKFXMLAKmXQFNDeg002ReIs",
    authDomain: "insideseat-dev.firebaseapp.com",
    databaseURL: "https://insideseat-dev.firebaseio.com",
    projectId: "insideseat-dev",
    storageBucket: "insideseat-dev.appspot.com",
    messagingSenderId: "726113934357",
    appId: "1:726113934357:web:f70ff6a1f2cb8a9d5bc21c"
};

var stagingConfig = {
    apiKey: "AIzaSyBH7ZhAu4m3mhddUWpWN4yL2ujlm_wGFpk",
    authDomain: "insideseat-uat.firebaseapp.com",
    databaseURL: "https://insideseat-uat.firebaseio.com",
    projectId: "insideseat-uat",
    storageBucket: "insideseat-uat.appspot.com",
    messagingSenderId: "278440022521",
    appId: "1:278440022521:web:c19310a83e6887dcf32d28"
};

var firebaseConfigProduction = {
  apiKey: "AIzaSyBYZ7CCTCUPreV0hjIo0imw35VKBbISsE0",
  authDomain: "insideseat.firebaseapp.com",
  databaseURL: "https://insideseat.firebaseio.com",
  projectId: "insideseat",
  storageBucket: "insideseat.appspot.com",
  messagingSenderId: "907731768528",
  appId: "1:907731768528:web:51e5b802bd8dffb843bad5",
  measurementId: "G-QPSN8JWGNX"
};


firebase.initializeApp(firebaseConfigProduction);
firebase.firestore().settings({ timestampsInSnapshots: true });

export default firebase 