import React, { Component } from 'react';
import Dashboard from './components/dashboard/Dashboard'
import AppRouter from './routes/AppRouter'

class App extends Component {
  render() {
    return (
      <AppRouter />
    )
  }
}
export default App;