import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import firebase from 'firebase/app'

import MessageNote from './MessageNote'

class MessageSendOtpEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            isDataFetched: false,
            editMessageData: {
                smsContent: ''
            }
        }
    }

    componentDidMount = () =>{
        const mainCategoryId = this.props.data.mainCategoryId;
        const subCategotyId = this.props.data.subCategotyId;

        firebase.ref(`/customMessages/${mainCategoryId}/${subCategotyId}`)
            .once('value', snap => {
                const subMessageData = snap.val();
                if(subMessageData != null){
                    this.setState({
                        editMessageData: subMessageData,
                        isDataFetched: true
                    })
                }
            })
            .catch((error) => {
                alert('Error in getting chat data')
                console.log(error);
            });
    }


    updateMessage = (e)=>{
        e.preventDefault();
        const mainCategoryId = this.props.data.mainCategoryId;
        const subCategotyId = this.props.data.subCategotyId;

        firebase.ref(`/customMessages/${mainCategoryId}/${subCategotyId}`).update(this.state.editMessageData);


        setTimeout(()=>{
            this.setState({
                redirect: true
            })
        }, 500)
    }

    handleChangeFor = (propertyName) => (event) => {
        const { editMessageData } = this.state;
        const newContact = {
          ...editMessageData,
          [propertyName]: event.target.value
        };

        this.setState({ editMessageData: newContact });
    }

    render() {

        if(this.state.redirect){
            return <Redirect to={'/message-category/'+this.props.data.mainCategoryId} />
        }

        return (
            <div className="row">
                <div className="col-md-6">
                    <div className="panel panel-default">
                        <div className="panel-heading">Send Otp
                            {!this.state.isDataFetched &&
                                <span className="spanSpiner"><i className="fa fa-cog fa-spin" aria-hidden="true"></i></span>
                            }
                        </div>
                        <div className="panel-body">
                            <form onSubmit={this.updateMessage}>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">SMS Content</label>
                                    <textarea className="form-control" rows={3} onChange={this.handleChangeFor('smsContent')} defaultValue={this.state.editMessageData.smsContent} />
                                </div>
                                <div className="panel-footer text-right" style={{ textAlign: 'center' }}>
                                    {this.state.isDataFetched &&
                                        <button className="btn btn-success" type="submit">Submit</button>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>{/* /panel */}
                </div>
                <div className="col-md-6">
                    <div className="panel panel-default">
                        <div className="panel-heading">Note </div>
                        <div className="panel-body">
                            <MessageNote />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default MessageSendOtpEdit