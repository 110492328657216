import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Header from './../includes/Header'
import LeftPanel from './../includes/LeftPanel'
import Footer from './../includes/Footer'


class Dashboard extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <LeftPanel />

        <div id="main-container">

          <div className="main-header clearfix">
            <div className="page-title">
              <h3 className="no-margin">Dashboard</h3>
            </div>{/* /page-title */}
          </div>{/* /main-header */}





          <div className="padding-md">
            <div className="row">
              <div className="col-sm-6 col-md-3">
                <Link to="/message-category">
                  <div className="panel-stat3 bg-danger">
                    <h2 className="m-top-none" id="userCount">Message</h2>

                    {/* <i className="fa fa-arrow-circle-o-up fa-lg" /><span className="m-left-xs">5% Higher than last week</span> */}
                    <div className="stat-icon">
                      <i className="fa fa-file-text-o fa-3x" />
                    </div>
                    <div className="loading-overlay">
                      <i className="loading-icon fa fa-refresh fa-spin fa-lg" />
                    </div>
                  </div>
                </Link>
              </div>{/* /.col */}

              <div className="col-sm-6 col-md-3">
                <Link to="/create-post">
                  <div className="panel-stat3 bg-danger">
                    <h2 className="m-top-none" id="userCount">Create Post</h2>

                    {/* <i className="fa fa-arrow-circle-o-up fa-lg" /><span className="m-left-xs">5% Higher than last week</span> */}
                    <div className="stat-icon">
                      <i className="fa fa-file-text-o fa-3x" />
                    </div>
                    <div className="loading-overlay">
                      <i className="loading-icon fa fa-refresh fa-spin fa-lg" />
                    </div>
                  </div>
                </Link>
              </div>{/* /.col */}
            </div>





          </div>
        </div>
        <Footer />
      </React.Fragment>
    )
  }
}
export default Dashboard;