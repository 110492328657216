import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import firebase from 'firebase/app'

import MessageNote from './MessageNote'

class MessageEditPostLocationDateTimeEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            isDataFetched: false,
            editMessageData: {
                pushNotificationContentExistingInvitees: '',
                pushNotificationContentNewInvitees: '',
                pushNotificationContentRemovedInvitees: '',
                smsContentExistingInvitees: '',
                smsContentNewInvitees: '',
                smsContentRemovedInvitees: ''
            }
        }
    }

    componentDidMount = () =>{
        const mainCategoryId = this.props.data.mainCategoryId;
        const subCategotyId = this.props.data.subCategotyId;

        firebase.ref(`/customMessages/${mainCategoryId}/${subCategotyId}`)
            .once('value', snap => {
                const subMessageData = snap.val();
                if(subMessageData != null){
                    this.setState({
                        editMessageData: subMessageData,
                        isDataFetched: true
                    })
                }
            })
            .catch((error) => {
                alert('Error in getting chat data')
                console.log(error);
            });
    }

    updateMessage = (e)=>{
        e.preventDefault();
        const mainCategoryId = this.props.data.mainCategoryId;
        const subCategotyId = this.props.data.subCategotyId;

        firebase.ref(`/customMessages/${mainCategoryId}/${subCategotyId}`).update(this.state.editMessageData);


        setTimeout(()=>{
            this.setState({
                redirect: true
            })
        }, 500)
    }

    handleChangeFor = (propertyName) => (event) => {
        const { editMessageData } = this.state;
        const newContact = {
          ...editMessageData,
          [propertyName]: event.target.value
        };

        this.setState({ editMessageData: newContact });
    }

    render() {

        if(this.state.redirect){
            return <Redirect to={'/message-category/'+this.props.data.mainCategoryId} />
        }
        return (
            <div className="row">
                <div className="col-md-6">
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            Edit Post LocationDate Time Edit
                            {!this.state.isDataFetched &&
                                <span className="spanSpiner"><i className="fa fa-cog fa-spin" aria-hidden="true"></i></span>
                            }
                        </div>
                        <div className="panel-body">
                            <form onSubmit={this.updateMessage}>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Push Notification Content Existing Invitees</label>
                                    <textarea className="form-control" rows={3} onChange={this.handleChangeFor('pushNotificationContentExistingInvitees')} defaultValue={this.state.editMessageData.pushNotificationContentExistingInvitees} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">Push Notification Content New Invitees</label>
                                    <textarea className="form-control" rows={3} onChange={this.handleChangeFor('pushNotificationContentNewInvitees')} defaultValue={this.state.editMessageData.pushNotificationContentNewInvitees} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">Push Notification Content Removed Invitees</label>
                                    <textarea className="form-control" rows={3} onChange={this.handleChangeFor('pushNotificationContentRemovedInvitees')} defaultValue={this.state.editMessageData.pushNotificationContentRemovedInvitees} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">SMS Content Existing Invitees</label>
                                    <textarea className="form-control" rows={3} onChange={this.handleChangeFor('smsContentExistingInvitees')} defaultValue={this.state.editMessageData.smsContentExistingInvitees} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">SMS Content New Invitees</label>
                                    <textarea className="form-control" rows={3} onChange={this.handleChangeFor('smsContentNewInvitees')} defaultValue={this.state.editMessageData.smsContentNewInvitees} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">SMS Content Removed Invitees</label>
                                    <textarea className="form-control" rows={3} onChange={this.handleChangeFor('smsContentRemovedInvitees')} defaultValue={this.state.editMessageData.smsContentRemovedInvitees} />
                                </div>

                                <div className="panel-footer text-right" style={{ textAlign: 'center' }}>
                                    {this.state.isDataFetched &&
                                        <button className="btn btn-success" type="submit">Submit</button>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>{/* /panel */}
                </div>
                <div className="col-md-6">
                    <div className="panel panel-default">
                        <div className="panel-heading">Note </div>
                        <div className="panel-body">
                            <MessageNote />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MessageEditPostLocationDateTimeEdit