import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import Header from './../includes/Header'
import LeftPanel from './../includes/LeftPanel'
import Footer from './../includes/Footer'
import _ from 'underscore'
import firebase from 'firebase/app'

class MessageSubCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDataFetched: false,
            subCategoryData: []
        }
    }


    componentDidMount = () => {
        const mainCategoryId = this.props.match.params.idval;
        firebase.ref(`/customMessages/${mainCategoryId}`)
            .once('value', snap => {
                const subMessageCate = snap.val();
                if (subMessageCate != null) {
                    let mainData = [];
                    _.object(_.map(subMessageCate, function (value, key) {
                        value.idVal = key;
                        mainData.push(value)
                        return value;
                    }));

                    this.setState({
                        subCategoryData: mainData,
                        isDataFetched: true
                    })
                }

            })
            .catch((error) => {
                alert('Error in getting chat data')
                console.log(error);
            });
    }


    render() {

        return (
            <React.Fragment>
                <Header />
                <LeftPanel />
                <div id="main-container">

                    <div className="main-header clearfix">
                        <div className="page-title" style={{width: '100%'}}>
                            <h3 className="no-margin">Message subcategoty</h3>
                            <Link to={'/message-category'}>
                                <button type="button" style={{float: 'right', marginTop: -25}} className="btn btn-info btn-sm"><i className="fa fa-reply" aria-hidden="true"></i> Back</button>
                            </Link>
                        </div>{/* /page-title */}
                    </div>{/* /main-header */}

                    <div className="padding-md">
                        <div className="panel panel-default table-responsive">


                            <table className="table table-striped" id="responsiveTable">
                                <thead>
                                    <tr>
                                        <th style={{ width: "93%" }}>Subcategoty List</th>
                                        <th />
                                    </tr>
                                </thead>
                                {this.state.subCategoryData.length > 0 &&
                                    <tbody>

                                        {this.state.subCategoryData.map((subCat, index) => (
                                            <tr key={subCat.idVal}>
                                                <td>{subCat.title}</td>
                                                <td>
                                                    <Link to={'/message-edit/'+this.props.match.params.idval+'/'+subCat.idVal}>
                                                        <button type="button" className="btn btn-primary btn-sm"><i className="fa fa-pencil-square-o" aria-hidden="true"></i> Edit</button>
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                }
                                {!this.state.isDataFetched > 0 &&
                                    <tr>
                                        <td colSpan={5} style={{textAlign:'center'}}><span className="spanSpinerList"><i className="fa fa-cog fa-spin" aria-hidden="true"></i></span></td>
                                    </tr>
                                }


                                {this.state.subCategoryData.length == 0 &&
                                    <tbody></tbody>
                                }
                            </table>
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}


export default withRouter(MessageSubCategory)